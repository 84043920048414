/* Tailwind CSS will handle most of the styling, but you can add custom styles if needed */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.icon-style {
  color: var(--primary-text-and-icon-colour); /* Use CSS variable or hardcoded color */
}

.primary-color {
  background-color: #0b5394; /* Adjust this color code to use your desired primary color */
  color: white;
}

.secondary-color {
  background-color: #E0A80F; /* Adjust this color code to use your desired secondary color */
  color: white;
}

.btn-modern {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 25px;
  border: none;
  transition: all 0.5s;
}

.btn-modern:hover {
  transform: scale(1.02);
  background-color: #adada9;
}

.element-modern {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.element-modern:hover {
  transform: scale(1.02);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
}

.primary-text-color {
  color: #007BFF; /* Adjust this color code to use your desired primary color for text */
}

.secondary-text-color {
  color: #C0C0C0; /* Adjust this color code to use your desired secondary color for text */
}

.active {
  background-color: #0056b3;
  color: white;
}

.same-width-button {
  width: 80%; /* Set the width to whatever you like */
}

.button-spacing {
  margin-top: 10px; /* Add space at the top */
  margin-bottom: 10px; /* Add space at the bottom */
}


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: 90%;
  max-height: 90%;
  height: auto;
  overflow: auto; /* Add scroll if content overflows */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.close-button {
  background: white;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

/* Make the image responsive */
.modal img {
  width: 100%;
  height: auto;
  max-height: 90vh;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.swal2-field-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swal2-label {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-size: 0.9em;
  box-sizing: border-box; /* Ensure padding is included in width */
  text-align: center;
}


input:checked + .slider {
  background-color: #0b5394;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.ReactModal__Overlay {
  z-index: 1000 !important; /* Make sure modal overlays everything */
}

